import {
  Box,
  ContentRow,
  FlowAccordion,
  FlowAccordionItem,
  useIsMobileLayout,
  Stack,
} from "design-system";
import { option, boolean } from "fp-ts";
import { useState } from "react";
import { useFormatMessage } from "../../intl";
import { PageHeading } from "../../Common/PageHeading/PageHeading";
import { Option } from "fp-ts/Option";
import { BackButton } from "../../Common/BackButton/BackButton";
import { AdditionalIncome } from "./AdditionalIncome/AdditionalIncome";
import { NonEmptyArray } from "fp-ts/NonEmptyArray";
import { ExpensesConfirmation } from "./ExpensesConfirmation/ExpensesConfirmation";
import { pipe } from "fp-ts/function";
import { emptyRework, Rework } from "../StandardLoanState";
import { MainContent } from "../../Common/MainContent";

type Props = {
  onNext: () => unknown;
  onCounterOffer: () => unknown;
  onPending: () => unknown;
  onRejected: () => unknown;
  onBack: () => unknown;
  showAdditionalIncomeStep: boolean;
  reworkData: Rework;
};

export function ExpensesAndAdditionalIncome(props: Props) {
  const formatMessage = useFormatMessage();
  const [
    showAdditionalIncomeStep,
    setShowAdditionalIncomeStep,
  ] = useState<boolean>(props.showAdditionalIncomeStep);
  const [
    isEditingAdditionalIncome,
    setIsEditingAdditionalIncome,
  ] = useState<boolean>(false);
  const [activeIndex, setActiveIndex] = useState<Option<number>>(
    option.some(
      props.showAdditionalIncomeStep &&
        option.isNone(props.reworkData.oldValues)
        ? 1
        : 0
    )
  );
  const [isHeaderReadonly, setIsHeaderReadonly] = useState(true);

  const onExpensesConfirmationNext = (needAdditionalIncomeStep: boolean) => {
    pipe(
      needAdditionalIncomeStep,
      boolean.fold(
        () => props.onNext(),
        () => {
          setShowAdditionalIncomeStep(needAdditionalIncomeStep);
          setActiveIndex(option.some(1));
        }
      )
    );
  };

  const expensesConfirmationStep: FlowAccordionItem = {
    title: formatMessage("StandardLoan.ExpensesConfirmation.Panel.title"),
    content: () => {
      return (
        <ExpensesConfirmation
          onExpensesConfirmationNext={onExpensesConfirmationNext}
          setIsHeaderReadonly={setIsHeaderReadonly}
        />
      );
    },
  };

  const additionalIncomeStep: FlowAccordionItem = {
    title: formatMessage("StandardLoan.AdditionalIncome.Panel.title"),
    content: () => (
      <AdditionalIncome
        onEditingStateChange={setIsEditingAdditionalIncome}
        onNext={props.onNext}
        onCounterOffer={props.onCounterOffer}
        onPending={props.onPending}
        onRejected={props.onRejected}
        reworkData={pipe(
          props.reworkData.oldValues,
          option.chain(oldValues => oldValues.additionalIncomeMap)
        )}
        reworkAll={props.reworkData.reworkAll}
      />
    ),
  };

  const items: NonEmptyArray<FlowAccordionItem> = pipe(
    props.reworkData.oldValues,
    option.fold(
      () => [
        expensesConfirmationStep,
        ...(showAdditionalIncomeStep ? [additionalIncomeStep] : []),
      ],
      () => [additionalIncomeStep]
    )
  );

  const isMobileLayout = useIsMobileLayout();
  const title = isHeaderReadonly
    ? "StandardLoan.ExpensesConfirmationAndAdditionalIncome.title"
    : "StandardLoan.ExpensesConfirmationAndAdditionalIncome.editTitle";

  return (
    <MainContent>
      <PageHeading title={formatMessage(title)} hideOnMobile />

      <Stack units={10} grow shrink column>
        <FlowAccordion
          items={items}
          value={activeIndex}
          onChange={setActiveIndex}
        />
        {props.reworkData === emptyRework && (
          <ContentRow type="lateral-margins">
            <Box column={isMobileLayout} grow>
              <BackButton
                action={props.onBack}
                disabled={isEditingAdditionalIncome}
              />
            </Box>
          </ContentRow>
        )}
      </Stack>
    </MainContent>
  );
}
